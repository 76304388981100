/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios/index';
import idx from 'idx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Header,
  Input,
  Label,
  Modal,
  Pagination,
  Table,
} from 'semantic-ui-react';
import { deleteUser, destroyUser } from '../../actions/user';
import { fetchUserProfilesList } from '../../actions/userProfiles';
import { fetchUserProfileSingle } from '../../actions/userProfileSingle';
import { destroyUsers, fetchUsers } from '../../actions/users';
import { getCurrentUserToken, isAccessToken } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import {
  EMPTY_STRING_VALUE,
  EMPTY_TABLE_MESSAGE,
  searchMethod,
} from '../../lib/utilities';
import { DEFAULT_ELEMENTS_PER_PAGE, PAGES_PATH } from '../../lib/variables';
import SearchBox from '../../ui/SearchBox';
import devLog from '../../lib/devLog';

import './UsersList.css';

const mapStateToProps = state => ({
  users: state.users,
  userProfileSingle: state.userProfileSingle,
  userProfileList: state.userProfilesList.data.rows,
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: (params = { qs: '' }) => {
    dispatch(fetchUsers(params));
  },
  deleteUser: username => {
    dispatch(deleteUser(username));
  },
  destroyUsers: () => {
    dispatch(destroyUsers());
  },
  destroyUser: () => {
    dispatch(destroyUser());
  },
  fetchUserProfileSingle: userProfileId => {
    dispatch(fetchUserProfileSingle(userProfileId));
  },
  fetchUserProfilesList: (params = { qs: '' }) => {
    dispatch(fetchUserProfilesList(params));
  },
});

class Users extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [
        {
          width: 2,
          label: 'ID',
          name: 'uid',
          type: 'number',
        },
        {
          width: 3,
          label: 'Username',
          name: 'username',
          type: 'text',
        },
        {
          width: 3,
          label: 'Email',
          name: 'email',
          type: 'email',
        },
        // { width: 3, label: 'Expiry', name: 'expiry', type: 'date' },
        // { width: 2, label: 'Profile ID', name: 'userprofileid', type: 'select', options: []}, // servizio API non funziona per ora
        // { width: 3, label: 'Commercial ADS', name: 'commercialads', type: 'text'}
      ],
      offset: 0,
      limit: DEFAULT_ELEMENTS_PER_PAGE,
      searchModel: {},
      sortModel: {
        label: '',
        direction: null,
        /* asc || desc || null */
      },
      userProfiles: [],
      modalOpen: false,
      id: null,
      username: null,
      check: null,
      userTodelete: {
        applications: 0,
        devices: 0,
        gateways: 0,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.selectProfileFormId = this.selectProfileFormId.bind(this);
    this.selectRoleFormId = this.selectRoleFormId.bind(this);

    this.searchBox = {
      doFetch: queryParams => {
        const qs = queryParams
          ? [...queryParams, 'not_roles[]=system&not_roles[]=admin'].join('&')
          : '';
        this.props.fetchUsers({ qs });
      },

      handlePageChange: (e, { activePage }) => {
        const { users, offset } = this.props;
        const {
          searchModel,
          sortModel,
          limit = DEFAULT_ELEMENTS_PER_PAGE,
        } = this.state;

        const newOffset = (activePage - 1) * users.limit;

        const currOffset = newOffset || (offset > 0 ? offset : null);

        const queryParams = searchMethod.genQs(
          searchModel,
          sortModel,
          currOffset,
          limit,
        );

        this.setState({ offset: newOffset });
        this.searchBox.doFetch(queryParams);
      },

      handleSearch: (e, el, newSortModel) => {
        const { searchModel, sortModel, limit } = this.state;

        let currSortModel = null;

        if (newSortModel) {
          currSortModel = { ...newSortModel };
        } else if (sortModel.label) {
          currSortModel = { ...sortModel };
        }

        const queryParams = searchMethod.genQs(
          searchModel,
          currSortModel,
          null,
          limit,
        );
        // debugger
        if (newSortModel) this.setState({ sortModel: newSortModel });
        this.searchBox.doFetch(queryParams);
      },

      handleSearchChange: (e, { name, value }) => {
        const { searchModel } = this.state;
        const newFormGroup = [...this.state.formGroup];
        const newSearchModel = { ...searchModel };

        const formIdx = newFormGroup.map(x => x.name).indexOf(name);
        switch (value) {
          case null:
          case '':
            delete newSearchModel[name];
            break;

          default:
            switch (name) {
              case 'expiry':
                newSearchModel[name] = encodeURIComponent(
                  moment(value).toISOString(),
                );
                break;
              default:
                newSearchModel[name] = encodeURIComponent(value);
            }
        }
        if (formIdx >= 0) newFormGroup[formIdx].selected = value;
        this.setState({
          searchModel: newSearchModel,
          formGroup: newFormGroup,
        });
      },

      handleSort: clickedColumn => () => {
        const { sortModel } = this.state;
        let direction = null;
        let label = '';
        if (sortModel.label === clickedColumn) {
          switch (sortModel.direction) {
            case 'asc':
              direction = 'desc';
              label = clickedColumn;
              break;

            case 'desc':
              direction = null;
              break;

            default:
              direction = 'asc';
              label = clickedColumn;
          }
        } else {
          direction = 'asc';
          label = clickedColumn;
        }
        this.searchBox.handleSearch(null, null, {
          ...sortModel,
          label,
          direction,
        });
      },

      handleSorted: column => {
        const { sortModel } = this.state;
        if (sortModel.direction && sortModel.label === column) {
          return sortModel.direction === 'asc' ? 'descending' : 'ascending';
        }
        return null;
      },
    };
  }

  componentDidMount() {
    const { limit } = this.state;
    const isPersonalToken = isAccessToken();

    this.setState({
      ...this.state,
      UserProfiles: this.props.UserProfiles,
      isAccessToken: isPersonalToken,
    });

    this.props.fetchUsers({
      qs:
        limit > 0 ? `limit=${limit}&not_roles[]=system&not_roles[]=admin` : '',
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.UserProfiles !== this.props.UserProfiles) {
      this.setState({
        ...this.state,
        UserProfiles: this.props.UserProfiles,
      });
    }
  }

  componentWillUnmount() {
    this.props.destroyUser();
  }

  // axiosFetchUserProfilesOptions() {
  //   // SERVIZIO PER CREARE SELECT PER FILTERING SU USER PROFILE ID
  //   Axios.get(url.UserProfilesOptions(), {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: getCurrentUserToken(),
  //     },
  //   })
  //     .then(res => {
  //       const newForm = [...this.state.formGroup];
  //       newForm[3].options = res.data;
  //       this.setState({ formGroup: newForm });
  //     })
  //     .catch(err => devLog(err));
  // }

  handleClose() {
    this.setState({ modalOpen: false, username: null, check: null });
  }

  handleGetAllDataByuserId(id) {
    const userTodelete = {
      applications: 0,
      devices: 0,
      gateways: 0,
    };
    Axios.get(`${url.Applications()}?uid=${id}&limit=1000`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => {
        const applications = res.data;
        userTodelete.applications = applications.rows.length;
        this.setState({
          ...this.state,
          ...{ userTodelete },
        });
      })
      .catch(err => devLog(err));

    Axios.get(`${url.Devices()}?uid=${id}&limit=1000`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => {
        const devices = res.data;
        userTodelete.devices = devices.rows.length;
        this.setState({
          ...this.state,
          ...{ userTodelete },
        });
      })
      .catch(err => devLog(err));

    Axios.get(`${url.Gateways()}?uid=${id}&limit=1000`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => {
        const gateways = res.data;
        userTodelete.gateways = gateways.rows.length;
        this.setState({
          ...this.state,
          ...{ userTodelete },
        });
      })
      .catch(err => devLog(err));
  }

  handleDeleteModal(id, username) {
    this.handleGetAllDataByuserId(id);
    this.setState({ modalOpen: true, id, username });
  }

  handleDelete() {
    const { id, limit } = this.state;

    this.props.deleteUser(id);
    this.props.destroyUser();
    setTimeout(() => {
      this.props.fetchUsers({
        qs:
          limit > 0
            ? `limit=${limit}&not_roles[]=system&not_roles[]=admin`
            : '',
      });
      this.props.fetchUserProfilesList({ qs: '' });
    }, 2000);

    this.setState({
      modalOpen: false,
      id: null,
      username: null,
      check: null,
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  selectProfileFormId = (id, profiles) => {
    const o = profiles.filter(profile => profile.id === id);
    return o.length ? o[0].label : '';
  };

  selectRoleFormId = (id, profiles) => {
    const o = profiles.filter(profile => profile.id === id);
    return o.length ? o[0].role : '';
  };

  render() {
    const { users } = this.props;
    const { formGroup, userTodelete, UserProfiles } = this.state;

    return (
      <Fragment>
        <Button
          as={Link}
          key="addNew"
          compact
          icon
          color="red"
          labelPosition="left"
          to={`${PAGES_PATH}/user/add`}
        >
          <FontAwesomeIcon
            icon="plus-circle"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Add user
        </Button>
        {formGroup.length && (
          <SearchBox
            formGroup={formGroup}
            startSearch={this.searchBox.handleSearch}
            updateSearch={this.searchBox.handleSearchChange}
          />
        )}
        <div style={{ width: '100%', overflowX: 'auto', marginTop: '1em' }}>
          {users.rows && users.rows.length > 0 ? (
            <Table sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('id')}
                    onClick={this.searchBox.handleSort('id')}
                  >
                    ID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('username')}
                    onClick={this.searchBox.handleSort('username')}
                  >
                    Username
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('email')}
                    onClick={this.searchBox.handleSort('email')}
                  >
                    Email
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('firstname')}
                    onClick={this.searchBox.handleSort('firstname')}
                  >
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('company')}
                    onClick={this.searchBox.handleSort('company')}
                  >
                    Company
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('UserProfileId')}
                    onClick={this.searchBox.handleSort('UserProfileId')}
                  >
                    Profile
                  </Table.HeaderCell>
                  <Table.HeaderCell className="unsortable">
                    Role
                  </Table.HeaderCell>
                  <Table.HeaderCell className="unsortable" textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {idx(users, accesory =>
                  accesory.rows.map(row => (
                    <Table.Row key={`row-${row.id}`}>
                      <Table.Cell>
                        <Link
                          to={{
                            pathname: `${PAGES_PATH}/user/${idx(
                              row,
                              _ => _.id,
                            )}`,
                            state: false,
                          }}
                        >
                          <strong>{idx(row, _ => _.id)}</strong>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Grid>
                          <Grid.Row columns={2}>
                            <Grid.Column Column width={4} stretched>
                              <Link
                                to={{
                                  pathname: `${PAGES_PATH}/user/${idx(
                                    row,
                                    _ => _.id,
                                  )}`,
                                  state: false,
                                }}
                              >
                                <strong>
                                  {idx(row, _ => _.username) || 'no username'}
                                </strong>
                              </Link>
                            </Grid.Column>
                            <Grid.Column
                              width={12}
                              textAlign="right"
                              only="computer large screen widescreen"
                            >
                              <Label as="a">
                                <SVG
                                  src="/assets/images/icoApps.svg"
                                  className={'icon-svg'}
                                  style={{}}
                                />{' '}
                                {idx(row, _ => _.count_applications) || '0'}/
                                {'∞'}
                              </Label>{' '}
                              <Label as="a">
                                <SVG
                                  src="/assets/images/icoDevices.svg"
                                  className={'icon-svg'}
                                  style={{}}
                                />{' '}
                                {idx(row, _ => _.count_devices) || '0'}/
                                {idx(row, _ => _.profile.maxdevices) || '∞'}
                              </Label>{' '}
                              <Label as="a">
                                <SVG
                                  src="/assets/images/icoMulticast.svg"
                                  className={'icon-svg'}
                                  style={{}}
                                />{' '}
                                {idx(row, _ => _.count_multicastgroups) || '0'}/
                                {idx(row, _ => _.profile.maxgroups) || '∞'}
                              </Label>{' '}
                              <Label as="a">
                                <SVG
                                  src="/assets/images/icoGateways.svg"
                                  className={'icon-svg'}
                                  style={{}}
                                />{' '}
                                {idx(row, _ => _.count_gateways) || '0'}/
                                {idx(row, _ => _.profile.maxgateways) || '∞'}
                              </Label>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => (
                          <a href={`mailto:${_.email}`}>{_.email}</a>
                        )) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.firstname) || EMPTY_STRING_VALUE}{' '}
                        {idx(row, _ => _.lastname)}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.company) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ =>
                          this.selectProfileFormId(
                            _.UserProfileId,
                            UserProfiles,
                          ) ? (
                            <Link
                              to={`${PAGES_PATH}/user-profile/${idx(
                                row,
                                __ => __.UserProfileId,
                              )}`}
                            >
                              {idx(row, __ => __.profile.label)}
                            </Link>
                          ) : (
                            EMPTY_STRING_VALUE
                          ),
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.profile.role) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Link
                          to={`${PAGES_PATH}/user/${idx(row, _ => _.id)}/edit`}
                        >
                          <FontAwesomeIcon icon="edit" />
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                        <Link
                          to="#"
                          onClick={() =>
                            this.handleDeleteModal(row.id, row.username)
                          }
                        >
                          <FontAwesomeIcon icon="trash-alt" />
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  )),
                )}
              </Table.Body>
            </Table>
          ) : (
            EMPTY_TABLE_MESSAGE
          )}
        </div>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>
              This action will delete this user and all that belongs to him:
            </h3>
            <ul>
              <li>Gateways: {userTodelete.gateways}</li>

              <li>Devices: {userTodelete.devices}</li>
              <li>Applications: {userTodelete.applications}</li>
            </ul>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the username</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {this.state.username}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={this.state.username !== this.state.check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
        <Container textAlign="center">
          {/*
             Pagination:
             SemanticUI  => https://react.semantic-ui.com/addons/pagination/
             Web Service => https://documenter.getpostman.com/view/7664580/SW11XeM5?version=8ed314ff-c1e3-48cf-b62e-0ba509032cb9#common
             */}
          {users && users.size > users.limit && (
            <Pagination
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              activePage={parseInt(users.offset / users.limit, 10) + 1}
              totalPages={Math.ceil(users.size / users.limit)}
              onPageChange={this.searchBox.handlePageChange}
            />
          )}
        </Container>
      </Fragment>
    );
  }
}

Users.propTypes = {
  users: PropTypes.object,
  fetchUsers: PropTypes.func,
  destroyUsers: PropTypes.func,
  destroyUser: PropTypes.func,
  deleteUser: PropTypes.func,
  fetchUserProfilesList: PropTypes.func,
  offset: PropTypes.object,
  userProfileList: PropTypes.array,
  UserProfiles: PropTypes.array,
};

const UsersList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Users),
);

export { UsersList };
